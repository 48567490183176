import React from "react"

const Play = ({ dark = false }) => {
  let fill = '#000';
  
  if (typeof dark === 'boolean' && dark === false) {
    fill = "#fff";
  }

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="19" viewBox="0 0 17 19">
      <path id="Polígono_8" data-name="Polígono 8" d="M9.5,0,19,17H0Z" transform="translate(17) rotate(90)" fill={fill}/>
    </svg>
  );
}

export default Play