import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import HeroBanner from '../components/insights/heroBanner';
import List from '../components/insights/list';

import ThemeContext from '../context/theme-context';

class InsightsPage extends React.Component {
    
    render() {
        const { isMobile, darkMode, currentLocale, intl } = this.context;

        

        return (
            <Layout
                showNavLinks={true}
                initialMenuToggle={true}
                hideContact={isMobile}
            >
                <SEO title="Insights" />
                <HeroBanner
                    title="Lorem ipsum <br>dolor sit."
                    file="https://codeandsoul.s3.amazonaws.com/banner-post-1.png"
                />
                <List />
            </Layout>
        );
    }
}

InsightsPage.contextType = ThemeContext;

export default InsightsPage;
