import React from 'react';

import Play from '../svg/play';

import { graphql, useStaticQuery } from 'gatsby';

const List = () => {

    const data = useStaticQuery(
        graphql`
            query {
                allCodeandsoulInsights(limit: 10, sort: {order: ASC, fields: post_id}) {
                    nodes {
                        banner
                        post_id
                        slug
                        title
                        description
                        post_date
                    }
                }
            }
        `);

    const InsightItem = (data, index) => {
        
        let id = data.post_id.toString();

        if ( id.length < 2 ) {
            id = `0${id}`;
        }
        
        return (
            <div
                className={`
                    insight-item
                    ${index === 0 ? 'first-insight' : ''}
                    ${index === 1 ? 'reverse' : ''}
                    ${index > 2 ? 'other-insight': ''}`
                }
                key={index}
            >
                <div className={`${index % 2 === 0 ? 'first' : 'second'}-wrapper`}>
                    {
                        index <= 2 ? 
                        <img className="insight-item-image" src={data.banner} alt=""/> :
                        ''
                    }
                </div>
                <div className={`${index % 2 === 0 ? 'second' : 'first'}-wrapper`}>
                    <p className="insight-item-id">{ id }</p>
                    <h3 className="insight-item-title" dangerouslySetInnerHTML={{__html: data.title}}></h3>
                    <p className="insight-item-desc" dangerouslySetInnerHTML={{__html: data.description}}></p>
                    <button className="insight-item-button">
                        <Play dark={ index === 1 || index === 2 } /> More</button>
                </div>
            </div>
        );
    };

    return (
        <section className="insights-list">
                { data['allCodeandsoulInsights']['nodes'].map(InsightItem) }
                <div className="button-wrapper">
                    <button className="more-insights">
                        Carregar mais insights
                    </button>
                </div>
        </section>
    );

};

export default List;
