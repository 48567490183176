import React from 'react';;

import Img from 'gatsby-image';

import { injectIntl } from 'gatsby-plugin-intl';

const HeroBanner = ({ title, file }) => {

    // const image = useStaticQuery(graphql`
    //     query {
    //         allCodeandsoulInsights {
    //             nodes {
    //                 banner
    //                 post_id
    //                 slug
    //                 title
    //             }
    //         }
    //     }
    // `);

    return (
        <section className="hero-banner-insights" datanav="white">
            <div className="container">
                <div className="wrapper-banner">
                    <h2 className="title-banner" dangerouslySetInnerHTML={{__html: title}}></h2>
                    <img className="image-banner" src={file} alt=""/>
                </div>
            </div>
        </section>
    );
};

export default injectIntl(HeroBanner);
